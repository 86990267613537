import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { SiteConfigSlice, createSiteConfigSlice } from 'slices/siteConfigSlice';
import { UserSlice, createUserSlice } from 'slices/userSlice';

export const useBoundStore = create<SiteConfigSlice & UserSlice>()(
	persist(
		(...a) => ({
			...createSiteConfigSlice(...a),
			...createUserSlice(...a),
		}),
		{ name: 'bound-store' },
	),
);
