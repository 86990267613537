import { StateCreator } from 'zustand';

export interface SiteConfigSlice {
	theme: 'theme-gaeldst';
	setTheme: (theme: SiteConfigSlice['theme']) => void;
}

export const createSiteConfigSlice: StateCreator<SiteConfigSlice> = (set) => ({
	theme: null,
	setTheme: (theme) => set(() => ({ theme: theme })),
});
