export type AdobeEvent =
	| 'logged_in'
	| 'page_view'
	| 'payment_started'
	| 'payment_cancelled'
	| 'payment_completed'
	| 'signout'
	| 'helptext_open'
	| 'accordion_open'
	| 'arrow_link';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fireAdobeEvent = (eventName: AdobeEvent, value?: { details: object }) => {
	const event = {
		event: eventName,
		...value,
	};
	console.log('fireAdobeEvent', eventName, value); // For testing purpose
	window.adobeDataLayer = window.adobeDataLayer || [];
	window?.adobeDataLayer?.push(event);
};
