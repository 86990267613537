import { Vendors } from 'features/Vendors/Vendors';
import { useEffect, useState } from 'react';
import { useBoundStore } from 'store';
import { Layout } from 'ui/components/4-habitats/Layout';
import { Meta } from './Meta';

export const LayoutFeature: React.FC<Content.PageContent> = ({ children, page, site }) => {
	const [themeClass, setThemeClass] = useState(null);
	const theme = useBoundStore((state) => state.theme);

	useEffect(() => {
		setThemeClass(theme);
	}, [theme]);
	return (
		<Layout themeClass={'sop-app ' + themeClass}>
			<Meta {...site} />
			<Vendors {...page} />
			{children}
		</Layout>
	);
};
