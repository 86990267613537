import { usePageContext } from 'application/contexts/PageContext';
import getConfig from 'next/config';
import { useState } from 'react';
import { AdobeTracking } from './Adobe/AdobeTracking';
import { CookieConsent, CookieConsentTypes } from './CookieConsent/CookieConsent';

export const Vendors: React.FC<Content.PageContent['page']> = ({ properties }) => {
	const adobeDefaultActive = false; // used for testing
	const [adobeTrackingActive, setAdobeTrackingActive] = useState(adobeDefaultActive);

	const pageContext = usePageContext();

	const { publicRuntimeConfig } = getConfig();
	const { APP_ENV: environment, COOKIE_CONSENT: cookieConsentActive } = publicRuntimeConfig ?? {};

	const handleThirdParties = (consent: CookieConsentTypes) => {
		consent.statistic && properties?.adobeTracking ? setAdobeTrackingActive(true) : setAdobeTrackingActive(false);
	};

	return (
		<>
			{adobeTrackingActive && <AdobeTracking pageContext={pageContext} environment={environment} defaultActive={adobeDefaultActive} />}
			{cookieConsentActive && <CookieConsent consentCallBack={(consent) => handleThirdParties(consent)}></CookieConsent>}
		</>
	);
};
