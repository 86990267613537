import { usePageContext } from 'application/contexts/PageContext';
import Head from 'next/head';

export type MetaProps = Content.PageContent['page'] & {
	siteTitle: string;
	hideFromSearchEngine: boolean;
};

export const Meta: React.FC<Content.PageContent['site']> = ({ properties }) => {
	const pageContext = usePageContext();
	const { url, name } = pageContext ?? {};
	const { title, hideFromSearchEngine } = properties ?? {};
	const pageTitle = title ? title : name;
	return (
		<Head>
			<title>{pageTitle}</title>
			<link rel="canonical" href={url?.split('?')[0]} />
			{/* Robots */}
			<meta name="robots" content={`${hideFromSearchEngine ? 'nofollow noindex noarchive nosnippet noimageindex' : 'follow index'}`} />
		</Head>
	);
};
