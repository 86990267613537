import { PageContextProps } from 'application/contexts/PageContext';
import getConfig from 'next/config';
import Script from 'next/script';
import { useEffect, useState } from 'react';
import { useBoundStore } from 'store';
import { AdobeEvent, fireAdobeEvent } from './fireAdobeEvent';
import { handleAdobeLinkClick } from './handleAdobeLinkClick';

/* eslint-disable @typescript-eslint/no-explicit-any */
declare global {
	interface Window {
		adobeDataLayer: { event: AdobeEvent; [key: string]: unknown }[];
	}
}
export interface AdobeTrackingProps {
	defaultActive?: boolean; // Used for test
	pageContext: Partial<PageContextProps>;
	environment: 'test' | 'prod';
}

export const AdobeTracking = ({ pageContext, environment, defaultActive = false }: AdobeTrackingProps) => {
	const { url, name, template, id } = pageContext ?? {};
	const { publicRuntimeConfig } = getConfig();

	const [scriptLoaded, setScriptLoaded] = useState(defaultActive);
	const userTypeName = useBoundStore((state) => state.userTypeName);
	useEffect(() => {
		if (url === '#' || !scriptLoaded) return;
		window.adobeDataLayer = window.adobeDataLayer || [];
		const handleClick = (event) => {
			const targetElement = event.target;
			if (targetElement.tagName.toLowerCase() === 'button') return handleAdobeLinkClick(targetElement);
		};

		document.addEventListener('click', handleClick);
		return () => {
			document.removeEventListener('click', handleClick);
		};
	}, [scriptLoaded, url]);

	useEffect(() => {
		if (!scriptLoaded || !pageContext || !userTypeName || !publicRuntimeConfig.DOMAIN_HOSTNAME) return;

		const pageInfo = {
			pageName: name,
			contentType: template,
			domain: publicRuntimeConfig.DOMAIN_HOSTNAME,
			id: id,
			language: 'da',
		};

		const userInfo = {
			userType: userTypeName,
		};

		fireAdobeEvent('page_view', { details: { pageInfo, userInfo } });
	}, [id, name, publicRuntimeConfig.DOMAIN_HOSTNAME, template, userTypeName, url, scriptLoaded, pageContext]);

	const testScript = '/js/adobe/test/58ae824d9566/6524ab2aa58c/launch-29e36ed540be-development.min.js';
	const prodScript = '/js/adobe/prod/58ae824d9566/6524ab2aa58c/launch-b9ee2bc73ac4.min.js';

	return <Script src={`${environment === 'prod' ? prodScript : testScript}`} onLoad={() => setScriptLoaded(true)} />;
};
