import { StateCreator } from 'zustand';

export interface UserSlice {
	userType: 'super' | 'regular';
	setUserType: (userType: UserSlice['userType']) => void;
	userTypeName: 'Sagsbehandler' | 'Bruger';
	setUserTypeName: (usesetUserTypeNamerType: UserSlice['userTypeName']) => void;
}

export const createUserSlice: StateCreator<UserSlice> = (set) => ({
	userType: null,
	setUserType: (userType) => set(() => ({ userType: userType })),
	userTypeName: null,
	setUserTypeName: (userTypeName) => set(() => ({ userTypeName: userTypeName })),
});
